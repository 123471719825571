.crypto-explorer {
    font-family: 'Poppins', sans-serif;
    background: #0f0f23;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    max-width: 100%; /* Keep this for responsiveness */
    overflow: hidden;
    flex: 1;
    margin-right: 1rem;
    text-align: center;
    overflow: scroll;

  }
  
  
  .crypto-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .crypto-table-header {
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
  }
  
  .crypto-table-row-header th {
    color: #000000;
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: medium;
    cursor: pointer;
  }
  
  .crypto-table-row {
    background-color: #181834;
    color: #fff;
    transition: transform 250ms ease-in-out, background-color 250ms ease;
    font-size: medium;
  }
  
  .crypto-table-row:hover {
    transform: translateY(-3px);
    background-color: #1e1e2f;
  }
  
  .crypto-table-row:nth-child(even) {
    background-color: #151521;
  }
  
  .crypto-table-row:hover .crypto-logo {
    transform: scale(1.1);
  }
  
  .crypto-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: transform 250ms ease-in-out;
    margin: 20px;
  }
  
  .crypto-name, .crypto-market-cap, .crypto-volume, .crypto-price, .crypto-change {
    padding: 1rem;
    transition: color 250ms ease-in-out;
    font-size: medium;
  }
  
  .crypto-table-row:hover .crypto-name,
  .crypto-table-row:hover .crypto-market-cap,
  .crypto-table-row:hover .crypto-volume,
  .crypto-table-row:hover .crypto-price,
  .crypto-table-row:hover .crypto-change {
    color: #ff9f00;
  }
  
  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #0f0f23;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #6e48aa;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #9d50bb;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
  
  
    .crypto-table-row-header th, .crypto-name, .crypto-market-cap, .crypto-volume, .crypto-price, .crypto-change {
      padding: 0.5rem;
      font-size: x-small;
    }
  
    .crypto-logo {
      width: 30px;
      height: 30px;
    }
    .crypto-explorer {
        overflow: scroll;
      }
  }
  
  .main {
    display: flex; /* Enables Flexbox layout */
    flex-wrap: wrap; /* Allows items to wrap if needed */
    align-items: flex-start; /* Aligns items at the start of the flex container */
    background-color: #000000;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .main {
      flex-direction: column;
    }
      
  
  }






/* Modal Backdrop */
.modal-backdrop {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 12, 16, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

/* Modal Content */
.TE_Token_Info {
    background: linear-gradient(145deg, #0f0c29, #302b63, #24243e);
    color: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
    max-width: 600px;
    width: 90%;
    animation: modalFadeIn 0.5s ease-out forwards;
    border: 1px solid #6c63ff;
}

/* Modal Header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Close Button */
.modal-close-button {
    cursor: pointer;
    border: none;
    background-color: #ff4655;
    color: #fff;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.modal-close-button:hover {
    background-color: #ff2e4c;
}

/* Token Logo */
.token-logo-modal {
    display: block;
    margin: 0 auto 20px auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.token-logo-modal:hover {
    transform: scale(1.05);
}

/* Modal Body */
.modal-body {
    font-size: 16px;
    line-height: 1.6;
}

.modal-body p {
    margin-bottom: 15px;
}

/* Fade-in Animation */
@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Enhancements for interactivity & readability */
.TE_Token_Info * {
    transition: color 0.3s ease;
}

.TE_Token_Info:hover * {
    color: #dcdcdc;
}

/* Styling for specific text, if needed */
.strong-text {
    font-weight: bold;
    color: #a8ff78;
}

/* Additional Styling */
/* Add any specific styling you might need for other elements */
