:root {
    --primary-color: #ffcd4e; /* Primary accent color */
    --background-color: #1e0202; /* Dark background color */
    --text-color: #ffffff; /* Default text color */
    --hover-color: #eab543; /* Hover state color */
  }
  
  .main-container {
    font-family: 'Your Preferred Font', sans-serif;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 30px;
  }
  
  .navbar-logo {
    display: flex; /* Establishes flex container */
    align-items: center; /* Vertically centers the flex items */
    gap: 0.5rem; /* Adjust the space between logo and title as needed */
  }
  
  .logo-image {
    height: 40px; /* Or your preferred size */
  }
  
  .page-title {
    font-size: large; /* Or your preferred size */
    color: var(--text-color);
    margin: 0; /* Remove default margin */
    white-space: nowrap; /* Prevents the title from wrapping */
    overflow: hidden; /* Keeps the text within the navbar */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
  }
  .navbar-links {
    display: flex;
  }
  
  .nav-link {
    color: var(--text-color);
    text-decoration: none;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover, .nav-link:focus {
    color: var(--primary-color);
  }
  
  .navbar-actions {
    /* Add styling for other actions if needed */
  }
  
  .connect-wallet-btn {
    background-color: var(--primary-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .connect-wallet-btn:hover, .connect-wallet-btn:focus {
    background-color: var(--hover-color);
  }
  
  /* Add responsive styling as needed */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      padding: 1rem;
    }
  }

  .wallet-address-display {
    color: var(--primary-color);
    background-color: var(--background-color);
    border-radius: 15px;
    padding: 0.25rem 0.75rem;
    margin-right: 1rem;
    font-size: 0.9rem;
    line-height: 1.5;
    display: inline-block; /* or 'block' if you want it to be on a new line */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .wallet-info {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    flex-direction: column;
}
  
  .wallet-address {
    background: rgba(255, 255, 255, 0.1);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 30px;
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer; /* Indicates that the element is interactive */
  position: relative; /* For tooltip positioning */
  }
  
  .wallet-balances-tooltip {
    visibility: hidden;
    position: absolute; /* Or 'fixed' if you want it to be relative to the viewport */
    z-index: 9999; /* Ensure this is higher than other elements */
    top: 100%; /* Position it at the bottom of the .wallet-info container */
    left: 50%;
    transform: translateX(-50%) translateY(0); /* Adjust to perfectly align below the wallet address */
    background-color: var(--background-color); /* Make sure this provides a solid color */
    color: var(--text-color);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5); /* Stronger shadow for better visibility */
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1.5rem; /* Adjusted for more space above */
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    width: auto; /* Or set a fixed width if necessary */
    max-width: 360px; /* Adjust as needed */
    border: 1px solid var(--primary-color); /* Optional: adds a border for better definition */
  }
  
  .wallet-address:hover ~ .wallet-balances-tooltip,
  .wallet-balances-tooltip:hover {
    visibility: visible; /* Show the tooltip on hover */
    opacity: 1;
  }
  
  

.wallet-balance {
  display: block; /* Stack spans on top of one another */
  margin: 0.25rem 0;
  text-align: left;
}

.wallet-address:hover .wallet-balances-tooltip {
  visibility: visible; /* Show the tooltip on hover */
  opacity: 1;
  transform: translateX(-50%) translateY(0); /* Move into final position */
}
  
  
  .connect-wallet-btn {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  

  @media (max-width: 768px) {
    .navbar-logo {
      gap: 0.25rem; /* Less space on smaller screens */
      margin-bottom: 10px;
    }
  
    .logo-image {
      height: 30px; /* Smaller logo on smaller screens */
    }
  
    .page-title {
      font-size: 0.75rem; /* Smaller font size on smaller screens */
    }
  }