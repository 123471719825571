

  action-buttons {
    background: #2a2f45; /* Dark background similar to the image */
    border-radius: 10px;
    padding: 20px;
    margin: 20px ; /* Centers the action-buttons div */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Spacing between buttons */
    width: fit-content;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr; /* Three columns: left, middle, right */
    gap: 20px; /* Adjust the gap between columns */
    align-items: center; /* Vertically center content */
    margin-top: 30px;
  }
  
  /* Style for the left side (image) */
  .left-side {
    text-align: center;
  }
  
  /* Style for the app logo */
  .app-logo {
    max-width: 100px; /* Adjust the maximum width of the logo */
    height: auto; /* Maintain the aspect ratio of the logo */
    animation: rotate 20s linear infinite;

  }
  
  /* Style for the middle (app name) */
  .middle {
    text-align: center;
  }
  
  
  
  /* Style for the right side (action buttons and wallet address) */
  .right-side {
    text-align: center;
  }
  
  .wallet-address-display{
    background-color:#1e1e1e;  /* Matching background color */
    color: #ffffff;
    padding: 12px 20px;
    border-radius: 20px;
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-left: 10px;
    border: 1px solid #304FFE;
    font-size: small;

  }
  
  .wallet-address-display:hover, .wallet-balance-display:hover, .custom-token-balance-display:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  .custom-token-balance-display {
    background-color: #1e1e1e; /* Matching background color */
    color: #ffffff; /* White text color */
    padding: 12px 20px;
    border-radius: 20px;
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: grid;
    gap: 8px; /* Adds space between items in the grid */
    grid-template-columns: 1fr; /* Adjusts columns in the grid */
    align-items: center;
    justify-items: center; /* Ensures content is centered */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    border: 1px solid #304FFE;
    margin-right: 10px;
}

.custom-token-balance-display:hover {
    transform: translateY(-5px); /* Subtle hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.custom-token-balance-display span {
    font-size: 16px; /* Adjusted font size */
    font-weight: normal; /* Less emphasis on the value's weight */
    color: #e0e0e0; /* Lighter shade for better contrast */
    text-shadow: none; /* Clearer text */
}


  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .custom-token-balance-display {
      padding: 10px 15px; /* Smaller padding on smaller screens */
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  
    .custom-token-balance-display span {
      font-size: 16px; /* Adjust value font size accordingly */
    }
  }
  

  .connect-wallet-button,
  .buy-tickets-button {
    background: #4c8bf5; /* Blue background for the buttons */
    border: none;
    border-radius: 20px; /* Rounded borders for the buttons */
    color: white;
    font-size: 1em;
    padding: 15px 30px; /* Padding inside the buttons */
    text-transform: uppercase;
    letter-spacing: 1px; /* Spacing between letters for a more defined look */
    width: 100%; /* Buttons take full width of the .action-buttons div */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button background color */
    margin-left: 10px;
    
  }
  
  .connect-wallet-button:disabled,
.buy-tickets-button:disabled {
  background: #3a3f55; /* Darker background for disabled state */
  cursor: not-allowed;
}

.connect-wallet-button:not(:disabled):hover,
.buy-tickets-button:not(:disabled):hover {
  background: #6699ff; /* Lighter blue for hover state */
}


  .connect-wallet-button {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite, pulse 2s infinite;  
}
  
:root {
    background: black; /* Dark background similar to the image */
    color: white; 
}

.info-value {
    font-size: 24px;
    font-weight: bold;
    animation: pulse 2s infinite;

  }
  .percentage-buttons-container {
    display: flex;
    margin-top: 10px; /* Adjust spacing as needed */
}

.percentage-button {
    background-color: #4c8bf5; /* Blue background */
    border: none;
    color: white;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease; /* Smooth transitions */
}

.percentage-button:hover {
    background-color: #6699ff; /* Lighter blue on hover */
    transform: translateY(-2px); /* Lift effect on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow on hover */
}

.percentage-button:focus {
    outline: none; /* Remove default focus outline */
}

.percentage-button:active {
    transform: translateY(1px); /* Press effect */
}


  

  @media (max-width: 768px) {
    .lottery-container {
        padding: 10px;
    }

    .grid-container {
        display: list-item;
        grid-template-columns: 1fr 1fr; /* Two equal columns */
        gap: 20px; /* Adjust as needed */
      }
    }
    @keyframes scrollLeft {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-50%);
        }
    }
    
    .scrolling-banner {
        overflow: hidden;
        background-color: #4c8bf5; /* Adjust the background color as needed */
        color: #fff; /* Text color */
    }
    
    .scrolling-banner .scroll-content {
        display: flex; /* Aligns items in a row */
        animation: scrollLeft 30s linear infinite;
    }
    
    .scrolling-banner .scroll-content p {
        margin-right: 50px; /* Adds space between paragraphs */
        white-space: nowrap; /* Prevents text wrapping */
    }
    
    .scrolling-content.paused {
        animation-play-state: paused;
      }


/* Navbar links styles */
.navbar-links {
  display: flex; /* Aligns links horizontally */
  align-items: center; /* Vertically centers the links */
  margin-bottom: 20px;
}

.navbar-links nav ul {
  list-style-type: none; /* Removes default list bullets */
  padding-left: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
  display: flex; /* Display the list items in a row */
}

.navbar-links nav ul li {
  margin: 0 10px; /* Space out the list items */
}

.navbar-links nav ul li a {
  text-decoration: none; /* Removes the underline from links */
  color: #ffffff; /* White color text for the links */
  background-color: transparent; /* No background to start with */
  padding: 10px 20px; /* Padding for clickable area */
  border-radius: 5px; /* Slightly rounded corners for aesthetics */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  font-weight: 500; /* Slightly bolder font */
  font-size: 20px;}

/* Hover effect for links */
.navbar-links nav ul li a:hover,
.navbar-links nav ul li a:focus {
  background-color: #ffcd4e; /* Highlight color for hover and focus */
  color: #2c2f36; /* Color change for text on hover and focus */
  cursor: pointer; /* Change cursor to indicate clickable */
}

/* Active link styles */
.navbar-links nav ul li a.active {
  color: #ffcd4e; /* Highlight color for the active link */
  border-bottom: 3px solid #ffcd4e; /* Underline style for active state */
}






/* General Reset */
* {
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: x-small;
}

/* Global Dark Theme Styles */
body {
  background-color:  #040e1a;
    color: #e0e0e0;
}
.swap-container {
  display: flex;
  justify-content: space-between; /* Distributes space between children */
  align-items: stretch; /* Ensures children fill the container vertically */
  padding: 60px;
  max-width: auto; /* Restrict maximum width */
  max-height: 1200px; /* Set a maximum height */
  margin: auto auto; /* Center horizontally with margin */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
  border-radius: 8px; /* Soften edges */
  gap: 20px; /* Creates space between children */
  overflow: auto; /* Handle overflow content */
}



.swap-container > div:first-child {
  /* Style for the first child div */
  flex-grow: 1; /* Allows the child to grow */
  margin-right: 10px; /* Adds margin to the right */
  overflow: auto; /* Ensures content can scroll if it overflows */
  max-width: 550px;
}

.swap-container > div:last-child {
  /* Style for the second child div */
  flex-grow: 1; /* Allows the child to grow */
  margin-left: 10px; /* Adds margin to the left */
  overflow: auto; /* Ensures content can scroll if it overflows */
  max-width: 1000px;
}

@media (max-width: 768px) {
  .swap-container {
    flex-direction: column; /* Stack children vertically on smaller screens */
    padding: 0px; /* Reduce padding */
    gap: 10px; /* Adjust gap for vertical stacking */
    max-height: none; /* Remove max-height restriction for vertical layout */
  }
  .swap-container > div:first-child,
  .swap-container > div:last-child {
    margin: 0; /* Reset margins for mobile layout */
    overflow: hidden; /* Adjust if necessary for mobile layout */
  }

  
}

@media (max-width: 900px) {
  .swap-container {
    flex-direction: column; /* Stack children vertically on smaller screens */
    padding: 0px; /* Reduce padding */
    gap: 10px; /* Adjust gap for vertical stacking */
    max-height: none; /* Remove max-height restriction for vertical layout */
    text-align: center;
  }

  .swap-container > div:first-child {
    text-align: center;
    margin-right: auto;
  }
}





/* Swap Interface */
.swap-interface {
  background: #020811;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 480px;
  margin: auto; /* Centers the element horizontally */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transitions for shadow and scale */
  border: 2px solid #334f7d; /* Added border with a subtle color for depth */
}

.Token-In {
  background: #020811;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid #334f7d; /* Added border with a subtle color for depth */

}


/* Focus within - for accessibility and to highlight when any child element is focused */
.swap-interface:focus-within {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25), 0 0 0 3px rgba(100, 100, 255, 0.5); /* Add a distinct shadow and a blue glow to indicate focus */
  outline: none; /* Remove default outline */
  border-color: #6a9bd8; /* Further brighten the border color to indicate focus */
}

.title {
  color: #00adb5;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

/* Form Controls */
.form-control {
  margin-bottom: 15px;
  margin-top: 20px;
}

.form-control label {
  display: block;
  margin-bottom: 5px;
  color: #bbbbbb;
}

.form-control input {
  width: 100%;
  padding: 10px;
  background-color: #060825;
  border: 1px solid #ffffff;
  border-radius: 4px;
  font-size: 16px;
  color: #e0e0e0;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control input:focus {
  outline: none;
  border-color: #00adb5;
  box-shadow: 0 0 0 2px rgba(0, 173, 181, 0.2);
}

/* Buttons */
button {
  padding: 10px 15px;
  background-color: #ffffff;
    color: #222831;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: #88db53;
  transform: translateY(-2px);
}

/* Swap Button */
.swap-button {
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
    margin-bottom: 5px;
    background: #4bbe3d ;
}

.swap-tokens{
  background: none;
  text-align: center;
  width: 100%;
  font-size: 18px;
}

.swap-tokens:hover {
  background-color: #05c0b7;
  transform: translateY(-2px);
  background: none;
}

.token-info-contract {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

/* Token Display and Modal Styles */
.token-info-logo, .token-info-name, .token-info-price, .token-info-contract {
  margin-bottom: 10px;
}

.token-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: transform 0.2s;
}

.token-logo:hover {
  transform: scale(1.1);
}

.select-token-button{
  width: 300px;
}

/* Modal Overlay */
.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.modal-content {
  position: fixed; /* Use fixed positioning to stay in place on scroll */
  top: 50%; /* Center the modal vertically */
  left: 50%; /* Center the modal horizontally */
  transform: translate(-50%, -50%); /* Adjust for true centering */
  width: 90%; /* Responsive width, adjusts to screen size */
  max-width: 500px; /* Maximum width to maintain readability */
  border-radius: 20px; /* Rounded corners for a softer look */
  padding: 30px; /* Padding inside the modal for content spacing */
  background: #020811; /* Dark background color for contrast with white text */
  color: #ffffff; /* White text color for readability */
  box-shadow: 0 4px 15px rgba(0,0,0,0.2); /* Shadow for a floating effect */
  z-index: 100; /* Ensure modal is above other content */
  border: 2px solid #334f7d; /* Border color for definition */
  overflow-y: auto; /* Enable vertical scrolling for overflow content */
  max-height: 80vh; /* Limit height to prevent going off-screen */
}

.modal-content-wallet {

  border-radius: 20px; /* Rounded corners for a softer look */
  padding: 30px; /* Padding inside the modal for content spacing */
  background: #040e1a; /* Dark background color for contrast with white text */
  color: #ffffff; /* White text color for readability */
  box-shadow: 0 4px 15px rgba(0,0,0,0.2); /* Shadow for a floating effect */
  z-index: 100; /* Ensure modal is above other content */
  border: 2px solid #334f7d; /* Border color for definition */
  overflow-y: auto; /* Enable vertical scrolling for overflow content */
  margin-left: 220px;
}




/* Custom Address Input */
.custom-address-input {
  display: flex;
  gap: 10px; /* Space between input and button */
}

.custom-address-input input {
  flex-grow: 1; /* Input takes up remaining space */
  padding: 10px;
  border: 1px solid #7f8c8d;
  border-radius: 4px;
  background-color: #2c3e50;
  color: #ecf0f1;
}

.custom-address-input button {
  padding: 10px 15px;
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-address-input button:hover {
  background-color: #1abc9c;
}

.modal-title {
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

.tokens-container {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.token-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  background-color: #2c3e50; /* Dark background for the token */
  border-radius: 20px; /* Rounded corners */
  color: #ecf0f1;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  border: 1px solid #ffffff;
}

.token-option:hover {
  background-color: #34495e; /* Slightly lighter on hover */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Soft shadow for depth */
}

.token-logo {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
}

.token-name {
  font-weight: 300; /* Make the token name bold */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  /* ... your existing styles ... */
}

.close-button {
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
}

.close-button:hover {
  background-color: #138496;
}

.custom-address-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.custom-address-input {
  flex-grow: 1;
  margin-right: 10px;
  /* ... your existing styles ... */
}



.tokens-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  /* ... your existing styles ... */
}

.token-option {
  /* ... your existing styles ... */
  background-color: #060825;
  transition: all 0.3s ease;
}

.token-option:hover {
  background-color: #350b0b;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.token-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.token-name {
  font-size: 14px; /* Adjusted for space */
}

.copy-button {
  background-color: none; /* Green background */
  background: none;
  color: white; /* White icon/text */
  border-radius: 60px;
}

/* Adjusts the size of icons within buttons for consistency */
.copy-button svg {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .swap-interface {
      margin: 10px;
  }

  .modal-content {
  
    width: 350px; /* Adjust width as needed */
    
    
  }
  .select-token-button{
    width: 190px;
  }

  .Token-In{
    padding: 5px 5px;  }

    .form-control input {
      width: 75%;
      
  }
  
}

/* Add this CSS to staking.css or a new CSS file */

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }

  
}

@media (max-width: 768px) {

  #dexscreener-embed iframe {
    position: absolute;
    width: 100%;
    height: 1300px;
    top: 0;
    left: 0;
    border: 10;
  }

  #dexscreener-embed{
    display: none;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 10;
}

.add-to-metamask-button {
  background-color: #f6851b; /* MetaMask color */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  border-radius: 60px;
}

.add-to-metamask-button img {
  height: 20px; /* Adjust based on your design needs */
  width: auto;
}

.switch-button{
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 18px;
width: 50%;
}

.swap-interface-buttons{

}

.swap-icon {
  font-size: 24px; /* Adjust the size as needed */
  color: #ea8c12; /* Adjust the color as needed */
}

.token-balances {
  margin: 1rem 0;
}

.token-balance {
  margin-bottom: 0.5rem;
}

.token-balance strong {
  color: #4caf50; /* Example color */
}


.percentage-inputs{
  margin: auto;
  text-align: center;
}

.percentage-inputs button {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}

.percentage-inputs button:hover {
  background-color: #e2e2e2;
}

.button-on {
  background-color: green;
  color: white;
}

.button-off {
  background-color: red;
  color: white;
}



/* TokenBalances.css */
.wallet-balances {
  max-width: 800px;
  margin: 30px auto;
  padding: 20px;
  background-color: #121212; /* Dark background */
  border-radius: 10px;
  color: #fff; /* White text color */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.wallet-balances h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.wallet-balances input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #666;
  background-color: #1a1a1a; /* Slightly lighter than background for contrast */
}

/* Token list styles, using grid for alignment */
.token-balances-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  border-top: 1px solid #333; /* Border color for separation */
}

.token-balances-list li {
  padding: 15px;
  background-color: #222; /* Each item background */
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
}

.token-balances-list li:hover {
  background-color: #333; /* Hover effect */
}

.token-name, .token-balance {
  font-size: 18px;
  font-weight: bold;
}

/* Separate token name and its USD value for styling */
.token-name .usd-value {
  display: block; /* New line for USD value */
  color: #4CAF50; /* Green color for positive values */
}

.wallet-balances-swap h1{
  font-size: xx-large;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wallet-balances {
    padding: 15px;
  }

  .wallet-balances h1 {
    font-size: 20px;
  }

  .wallet-balances-swap {
    padding: 15px;
  }

  .wallet-balances-swap h1 {
    font-size: 20px;
  }

  .token-balances-list {
    grid-template-columns: 1fr;
  }

  .token-name, .token-balance {
    font-size: 16px;
  }
}

/* TokenBalances.css */
.wallet-balances {
  background: #000; /* Dark background for the entire container */
  color: #fff; /* White text for better contrast */
  font-family: 'Arial', sans-serif;
  text-align: center;
  border-radius: 8px;
  padding: 20px;
  margin: auto;
  max-width: 768px;
}


.token-balances-container {
  text-align: left; /* Align the text to the left within the container */
}

.token-balances-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.token-table {
  width: 100%; /* Full width of the container */
  border-collapse: collapse; /* Remove any default spacing */
}

.token-table th,
.token-table td {
  padding: 10px;
  border: 1px solid #2c2c2e; /* Subtle borders for the cells */
  font-size: small;
}

.token-table th {
  background: #1c1c1e; /* Slightly lighter background for header */
  color: #fff;
}

.token-table td {
  background: #000; /* Background for each cell */
  color: #fff;
}

/* Zebra Striping for rows */
.token-table tr:nth-child(even) td {
  background: #161618;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wallet-balances {
    padding: 10px;
  }

  .wallet-balances-swap {
    padding: 10px;
  }

  .token-table th,
  .token-table td {
    padding: 8px;
    font-size: x-small;
  }

  .modal-content-wallet {
    margin-left: 0px;

  }
}



.wallet-balances-swap {
  color: #fff; /* White text for better contrast */
  font-family: 'Arial', sans-serif;
  text-align: center;
  border-radius: 8px;
  padding: 20px;
  margin: auto;
  max-width: 768px;
}


.wallet-balances-swap {
  max-width: 800px;
  margin: 30px auto;
  padding: 0px;
  border-radius: 10px;
  color: #fff; /* White text color */
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.wallet-balances-swap  h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.wallet-balances-swap  input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #666;
  background-color: #1a1a1a; /* Slightly lighter than background for contrast */
}
/* Base styles for the table */

/* Base styles */
body {
  background-color: #040e1a;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #ffffff;
  margin: 0;
  padding: 20px;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.header {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th, .table td {
  padding: 12px 15px;
  text-align: left;
}

.table th {
  background-color: #333;
  border-bottom: 2px solid #444;
}

.table td {
  border-top: 1px solid #333;
}

.table tr:hover {
  background-color: #333;
  cursor: pointer;
}

/* Token styling */
.token-name {
  font-weight: bold;
  font-size: 1em;
}

.usd-price {
  font-size: 0.9em;
  color: #aaa;
  display: block; /* Makes price appear below token name */
}

/* Tax and risk indicators */
.taxes {
  font-size: 0.8em;
  margin-top: 4px; /* Adjust spacing as needed */
}

.buy {
  color: #4CAF50; /* Bright green for buy tax */
  font-size: 0.7em;

}

.sell {
  color: #F44336; /* Bright red for sell tax */
  font-size: 0.7em;

}

.risk {
  font-size: 0.8em;
  display: block; /* Ensures risk appears on a new line */
  margin-top: 4px; /* Adjust spacing as needed */
}

/* Balance and USD value */
.balance-usd {
  font-size: 0.9em;
}

.separator {
  margin: 0 5px;
  color: #555;
}

/* Enhancing visual appeal with gradients and shadows */
.table th {
  background: linear-gradient(to right, #242424, #333);
}

.table tr:hover {
  background: linear-gradient(to right, #333, #242424);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Responsive Design */
@media (max-width: 768px) {
  .table, .table thead, .table tbody, .table th, .table td, .table tr {
    display: block;
  }

  .table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table tr {
    margin-bottom: 10px;
  }

  .table td {
    border: none;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }

  .table td:before {
    position: absolute;
    top: 12px;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    text-align: left;
    font-weight: bold;
    color: #aaa;
  }

  /* Adjust font sizes for smaller screens */
  .token-name {
    font-size: 0.9em;
  }

  .usd-price, .taxes, .risk, .balance-usd {
    font-size: 0.8em;
  }

  /* Adjust container padding for smaller screens */
  .container {
    padding: 10px;
  }
}
.toggle-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.toggle-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.toggle-label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + .toggle-label {
  background: #4cd964;
}

input:checked + .toggle-label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.toggle-label:active:after {
  width: 28px;
}

.warning {
  color: red; /* Makes the text color red */
  font-weight: bold; /* Makes the text bold */
  margin-top: 8px; /* Adds space above the warning message */
  padding: 10px; /* Adds padding inside the warning container */
  background-color: #fff3f3; /* Light red background for better visibility */
  border-left: 3px solid red; /* Adds a solid left border for emphasis */
  border-radius: 4px; /* Rounds the corners of the border */
  font-size: larger;
}

.calculated-amount {
  background-color: #e1f5fe; /* Light blue background for emphasis */
  padding: 10px 15px; /* Spacing inside the element */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem; /* Text size */
  color: #0277bd; /* Darker blue for the text */
  border: 1px solid #b3e5fc; /* Light blue border */
  display: inline-block; /* Adjusts width to content */
  width: 100%;
  text-align: center;
  font-size: medium;
}


/* Container styling with a slight gradient background */
.slippage-tolerance-container {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #6e48aa, #9d50bb); /* A vibrant gradient */
  border-radius: 8px; /* Rounded corners for a modern look */
  padding: 10px 15px;
  color: #FFFFFF; /* White text for contrast */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  justify-content: space-between;
}

/* Edit icon with dynamic color and shadow for emphasis */
.slippage-tolerance-container .fas {
  margin-left: 10px;
  color: #FFD700; /* Gold color to stand out */
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.7); /* Glowing effect */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transitions for interactivity */
}

/* Interactive hover effects */
.slippage-tolerance-container:hover {
  background: linear-gradient(135deg, #5643fa, #9d50bb); /* Shift gradient on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Deeper shadow for pop effect */
}

.slippage-tolerance-container .fas:hover, .slippage-tolerance-container .fas.active {
  transform: scale(1.15) rotate(15deg); /* Enlarge and rotate for dynamism */
  color: #C0C0C0; /* Silver color for a change */
}

/* General layout adjustments */
.swap-container-button {
  display: flex;
    align-items: center;
  gap: 20px; /* Adds space between slippage tolerance and swap button */
  margin: 10px;
  align-content: flex-start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
/* Keyframes for the pulse effect */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7); /* Adjusted for green glow */
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 10px 20px rgba(0, 255, 0, 0); /* Wider green glow */
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
}

/* Swap button styling with vibrant green background */
.swap-button1 {
  background-color: #4CAF50; /* Vibrant green color */
  color: #FFFFFF; /* White text for readability */
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px; /* Rounded corners for a modern look */
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  animation: pulse 2s infinite; /* Apply the pulse animation for attraction */
  width: 300px;
}

/* Enhanced hover and active effect */
.swap-button1:hover, .swap-button1:active {
  background-color: #43A047; /* Slightly darker green for tactile feedback */
  transform: translateY(-2px); /* Raise effect for interactivity */
  animation: none; /* Stop the pulse effect on hover to reduce distraction */
}
