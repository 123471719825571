/* Base styles for the bottom menu bar with a dark theme and gradient effect */
.bottom-menu-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(145deg, #0f0c29, #302b63, #24243e); /* Aesthetic gradient background */
    color: #fff;
    padding: 12px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5); /* Strong shadow for depth */
    z-index: 1000;
  }
  
  .bottom-menu-bar a, .bottom-menu-bar button {
    text-decoration: none;
    color: #ffffff;
    /* font-size: 16px; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 5px 10px;
    border-radius: 10px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    display: flex;
    /* gap: 8px; */
    flex-direction: row;
    align-items: center;
}
  /* Hover and active effects with transformation for tactile feedback */
  .bottom-menu-bar a:hover, .bottom-menu-bar button:hover {
    transform: translateY(-2px);
    background-color: rgba(255, 255, 255, 0.2); /* Subtle highlight on hover */
  }
  
  .bottom-menu-bar .active, .bottom-menu-bar button:active {
    background-color: #1f1f1f; /* Darker shade for active state */
    transform: scale(1.05); /* Slightly scale up active or clicked item */
  }
  
  /* Icons added via CSS for each menu item */
  .bottom-menu-bar a:before, .bottom-menu-bar button:before {
    content: '';
    display: inline-block;
    height: 24px;
    width: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  
  /* Responsive: Hide on tablets and desktops */
  @media (min-width: 768px) {
    .bottom-menu-bar {
      display: none;
    }
  }
  