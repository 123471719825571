/* Base Styles */
.connect-wallet-center {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.network-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}




/* Card Style */
/* Center Alignment for Card */
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card Style */
.card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  /* Size */
  width: 900px; /* Fixed width */
  min-height: 200px; /* Minimum height */
}

.card:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}


/* Remaining Time Section */
.remaining-time {
  color: #1a1a1a;
}

.time-label {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
  transition: font-size 0.3s ease;
}

.timer {
  display: flex;
  justify-content: space-around;
  transition: opacity 0.3s ease;
}

.timer:hover {
  opacity: 0.8;
}

.time-unit {
  text-align: center;
  transition: transform 0.3s ease;
}

.time-unit:hover {
  transform: scale(1.05);
}

.number {
  font-size: 28px;
  color: #007aff; /* iOS blue */
  font-weight: 700;
  display: block; /* Makes the number take up the full width */
  transition: font-size 0.3s ease, color 0.3s ease;
}

.number:hover {
  font-size: 32px;
  color: #005ecb;
}

.unit {
  font-size: 16px;
  transition: font-size 0.3s ease;
}

/* Price Container */
.price-container {
  /* Inherits .card styles */
}

.price-label {
  font-size: 18px;
  color: #757575; /* Material grey */
  margin-bottom: 10px;
  font-weight: 500;
}

.price-box {
  font-size: 16px;
  color: #424242; /* Darker grey */
  padding: 10px;
  border: 1px solid #e0e0e0; /* Light grey */
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.price-box:hover {
  background-color: #f0f0f0;
  border-color: #007aff;
}

/* Sell Bar */
.sell-bar {
  background-color: #eceff1; /* Light grey */
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.sell-bar:hover {
  background-color: #dde1e4;
}

.sell-progress {
  height: 20px;
  background-color: #4caf50; /* Material green */
  text-align: center;
  color: white;
  line-height: 20px;
  border-radius: 5px;
  transition: width 0.5s ease;
}



.total-sold-price {
  margin-top: 15px;
  font-size: 50px;
  color: #333;
  font-weight: 500;
  text-align: center;
  animation: bounce 2s ease infinite; /* Adjust the duration and animation properties as needed */
}


.total-sold-price-label {
  margin-top: 15px;
  font-size: 30px;
  color: #333;
  font-weight: 500;
  transition: color 0.3s ease;
  text-align: center;

}

/* Purchase Form */
.purchase-form {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  transition: transform 0.3s ease;
}

.purchase-form:hover {
  transform: translateY(-2px);
}

.purchase-form input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #bdbdbd; /* Light grey */
  background-color: #f5f5f7; /* Soft gray */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.purchase-form input:focus {
  border-color: #007aff; /* iOS blue */
  box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.2);
}

.purchase-form button {
  background-color: #007aff; /* iOS blue */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.purchase-form button:hover {
  background-color: #005ecb; /* Slightly darker blue */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Style for the first <div> containing purchased tokens */
/* You can adjust these styles as needed */
.div-purchased-tokens {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0; /* Light grey */
  border-radius: 8px;
  text-align: center;
}

.div-purchased-tokens p {
  font-size: 18px;
  color: #333;
  
}

/* Style for the second <div> containing the Claim Tokens button */
/* You can adjust these styles as needed */
.div-claim-button {
  margin-top: 20px;
  text-align: center;
}

/* Style for the Claim Tokens button */
/* These styles match your existing .claim-button styles */
.claim-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.claim-button[disabled] {
  background-color: gray;
  cursor: not-allowed;
}

/* Style for the third <div> containing the Claim Start Date */
/* You can adjust these styles as needed */
.claim-start-date {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  text-align: center;
}
