/* TokenInfoComponent.css */
.token-info-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #040e1a; /* Dark background */
    color: white;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    border-radius: 20px;
    justify-content: center;
  }

  .token-info-container h2{
    font-size: 30px;
    font-family: monospace;
  }
  
  .token-info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .token-info {
    background-color: #09182a; /* Slightly lighter than the container background */
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    width: calc(50% - 20px); /* Adjust based on padding and margin */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid #d7d7d7;
        border-color: #1a3656;
  }

  .token-info-right{
    display: flex;
    align-items: center;
    border-left: 2px solid #1a3656; /* Keeps the border color on hover */
    width: calc(20% - 10px);
    justify-content: center;
  }
  
  .token-info h2 {
    margin-top: 0;
    color: white; /* Light blue */
    font-size: 20px;
    font-family: math;
  }
  
  .token-info span.yes {
    color: #32d74b; /* Green for yes */
    font-size: 15px;
    &:after {
        content: " \2713"; /* Unicode character for check mark */
      }
  }
  
  .token-info span.no {
    color: #ff453a; /* Red for no */
    font-size: 15px;
    &:after {
        content: " \2717"; /* Unicode character for 'X' */
      }

  }

  .token-info span.white{
    color:white;
    font-size: 15px;

  }
  
  /* Style for the special cases like Owner Address */
  .token-info.owner-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Add responsive design as needed */
  @media (max-width: 768px) {
    .token-info {
      width: 100%;
    }

    .token-info-row {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .token-info p{
        max-width: 200px;
    }

    .token-info-container h2{
        font-size: 20px;
      }

      .token-info-right{
       
        width: calc(30% - 10px);
      }
      
      
      
  }
  