/* LotteryInterface.css */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.4; }
}

.new-winner-notification {
  animation: flash 1s linear infinite;
}



/* Style for the winner table */
.winner-table {
  width: 50%;
  border-collapse: collapse;
  margin-top: 10px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.selected-lottery-details{

  justify-content: center;
}

/* Style for table headers */
.winner-table th {
  background-color: #007BFF;
  color: white;
  padding: 8px;
  text-align: left;
  
}

/* Style for table cells */
.winner-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

/* Style for table rows */
.winner-table tr:nth-child(even) {
  background-color: #b84b4b;
}


/* CSS for Scroll Buttons */
.scroll-past-lotteries {
  align-items: center;
  margin-top: 10px;
}

.scroll-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-button:hover {
  background-color: #0056b3;
}

/* Style for Scroll Button Prev */
.scroll-button.prev {
  margin-right: 10px;
}

/* Style for Scroll Button Next */
.scroll-button.next {
  margin-left: auto;
}

/* CSS for Scrollable Container */
.past-lotteries-section {
  max-height: 300px; /* Adjust the maximum height as needed */
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

/* Style for Winner Rows within Scrollable Container */
.winner-row {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  transition: background-color 0.3s;

}

.winner-row:hover {
  background-color: #e6e6e6;
}

.winner-row p {
  flex: 1;
  margin: 0;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 4px;
}

.modal {
  display: none; /* Hidden by default */
  /* Other styling for positioning and background */
}

.modal.open {
  display: block; /* Show modal when open class is added */
}

.modal-content {
  /* Styling for the modal content */
}

.close {
  /* Styling for the close button */
}

:root {
    background: black; /* Dark background similar to the image */
    color: white; 
}

.action-buttons {
    background: #2a2f45; /* Dark background similar to the image */
    border-radius: 10px;
    padding: 20px;
    margin: 20px ; /* Centers the action-buttons div */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Spacing between buttons */
    width: fit-content;
  }

/* General Styles */
.faib-grid-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
  margin: 40px auto;
  background-color: #121212;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  color: #e0e0e0;
  font-family: 'Roboto', sans-serif;
}

.faib-header {
  text-align: center;
  border-bottom: 2px solid #2a2a2a;
  padding-bottom: 20px;
}

.faib-title {
  font-size: 2.2em;
  font-weight: 500;
  color: #76ff03;
  margin: 0;
}

.faib-lottery-info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.faib-info-label {
  font-weight: 700;
  margin-right: 10px;
  color: #bdbdbd;
  font-size: 1.8em;
}

.faib-info-value {
  font-weight: 500;
  color: #4fc3f7;
  font-size: 1.8em;

}

/* Enhancements for Interactivity */
.faib-info-value:hover {
  color: #29b6f6;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faib-grid-container {
    margin: 20px;
    padding: 15px;
  }

  .faib-title {
    font-size: 1.8em;
  }
}

  

  
  /* Style for the left side (image) */
  .left-side {
    text-align: center;
  }
  
  /* Style for the app logo */
  .app-logo {
    max-width: 100px; /* Adjust the maximum width of the logo */
    height: auto; /* Maintain the aspect ratio of the logo */
    margin-left: 30px;
    animation: rotate 20s linear infinite;

  }
  
  /* Style for the middle (app name) */
  .middle {
    text-align: center;
  }
  
  /* Style for the app name */
 
  /* Style for the right side (action buttons and wallet address) */
  .right-side {
    text-align: center;
  }
  
  .wallet-address-display {
    margin-left: 10px; /* Adjust as needed */
    align-self: center; /* Aligns text vertically with button */
    /* Additional styling as needed */
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
  }

.lottery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #121212;
    color: white;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    
  }

  
  .ticket-button {
    background-color: #28a745; /* Green for available tickets */
    border: none;
    color: white;
    margin: 5px;
    padding: 0; /* Padding set to 0 for circle shape */
    cursor: pointer;
    box-sizing: border-box; /* Include padding and border in the button's dimensions */
    width: 40px; /* Fixed width and height for circle */
    height: 40px; /* Fixed width and height for circle */
    border-radius: 50%; /* Makes the button a circle */
    display: flex; /* Enables flexbox for centering content */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    flex: 0 0 auto; /* Prevents flex items from growing or shrinking */

}
@keyframes shadow-glow {
  0% {
    box-shadow: 0 0 5px #28a745;
  }
  50% {
    box-shadow: 0 0 15px #28a745;
  }
  100% {
    box-shadow: 0 0 5px #28a745;
  }
}


.ticket-button:hover {
    background-color: #218838; /* Darker green on hover for interactive feedback */
    transform: translateY(-2px); /* Slightly raises the button when hovered */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increases shadow to emphasize the hover effect */
}

  
  .ticket-button.selected {
    border: 2px solid #fff; /* White border for selected tickets */
    background-color: darkorange;
  }
  
  .connect-wallet-button,
  .buy-tickets-button {
    background: #4c8bf5; /* Blue background for the buttons */
    border: none;
    border-radius: 20px; /* Rounded borders for the buttons */
    color: white;
    font-size: 1em;
    padding: 15px 30px; /* Padding inside the buttons */
    text-transform: uppercase;
    letter-spacing: 1px; /* Spacing between letters for a more defined look */
    width: 100%; /* Buttons take full width of the .action-buttons div */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button background color */
    
  }
  
  .connect-wallet-button:disabled,
.buy-tickets-button:disabled {
  background: #3a3f55; /* Darker background for disabled state */
  cursor: not-allowed;
}

.connect-wallet-button:not(:disabled):hover,
.buy-tickets-button:not(:disabled):hover {
  background: #6699ff; /* Lighter blue for hover state */
}


  .connect-wallet-button {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite, pulse 2s infinite;  
}
  
  .buy-tickets-button {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;  }
  
  .feedback-message {
    margin-top: 20px;
  }
  
  /* Additional styles here to match the rest of your UI elements... */

  .ticket-button.bought {
    background-color: red;
    color: white; /* Optional: Change the text color for better readability */
    cursor: not-allowed; /* Optional: Change cursor to indicate it's not clickable */
  }

  .ticket-button.user-owned {
    background-color: green; /* color for tickets bought by the user */
    color: white;
    
  }
  
  .ticket-button.bought {
    background-color: red; /* color for tickets bought by others */
    color: white;
  }

  .ticket-button.current-user {
    background-color: #4c8bf5; /* Replace with your desired color */
  }
  
  .lottery-info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: #1a1a1a; /* Dark background */
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
    color: white; /* White text color */
  }
  
  .lottery-info {
    flex: 1 1 250px; /* Adjust as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #333; /* Slightly lighter border for distinction */
    padding: 20px;
    border-radius: 10px;
    width: 250px; /* Fixed width, you can adjust it as needed */
  }
  
  .info-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px; /* Spacing between label and value */
  }
  
  .info-value {
    font-size: 24px;
    font-weight: bold;
    animation: pulse 2s infinite;

  }
  
 
  /* Additional styles for buttons and other elements can go here */
/* CSS in your LotteryInterface.css or a separate stylesheet */

.legend {
    background: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    margin: 20px;
    color: white;
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); /* Adjust minmax for your preferred min width */
    gap: 10px;
}
  
.legend-item {
    display: flex;
    align-items: center;
    border: 1px solid #333;
    padding: 10px;
    border-radius: 10px;
    font-size: 0.8em; /* Adjust as needed */
    animation: fadeIn 1s;

}
  
.legend-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0; /* Prevents the color indicator from shrinking */
}
  
  .selected {
    background-color: darkorange;
  }
  .available {
    background-color: #28a745; /* Green for available tickets */
  }
  
  .bought {
    background-color: red;
  }
  
  .currentUser {
    background-color: #4c8bf5; /* Replace with your desired color */
  }

  .animate-bounce {
    animation: bounce 1s infinite;
}
    
  .ticket-selection-area {
   
    background: #1a1a1a;
    border-radius: 10px;
    margin: 20px;
    color: white;
    display: flex; /* Enables Flexbox */
    flex-wrap: wrap; /* Allows multiple rows if necessary */
    align-items: center; /* Aligns buttons vertically in the center */
    justify-content: flex-start; /* Aligns buttons to the start of the container */
    gap: 10px; /* Adjusts the gap between buttons */
  }

   

  @media (max-width: 768px) {
    .lottery-container {
        padding: 10px;
    }

   
    .action-buttons {
        padding: 15px;
        margin: 15px auto;
      }
    
      .connect-wallet-button,
      .buy-tickets-button {
        padding: 12px 25px;
      }
    
   
    .lottery-info-container {
        width: 100%; /* Adjust the width as needed */
        padding: 15px;
        margin: 10px;
        flex-direction: column;
      align-items: center;
    }

    .lottery-info{
        width: 100%;
        margin-bottom: 10px;
        flex-basis: 48%; /* Adjust for two columns, considering some margin */
        margin: 1%; /* Small margin for spacing */
        max-width: 48%; /* To ensure it doesn't grow beyond this width */
    }

    .legend {
        padding: 15px;
        margin: 15px;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust for smaller devices */
    }

    .legend-item {
        padding: 8px;
        font-size: 0.7em; /* Smaller font size for medium-sized devices */
    }
    .ticket-button, .connect-wallet-button, .buy-tickets-button {
        padding: 8px 15px;
        font-size: 14px;
    }

    .feedback-message {
        font-size: 14px;
    }

    /* Adjust the font size for better readability on small screens */
    .info-label, .info-value {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    /* Further adjustments for smaller mobile devices */
    .ticket-button, .connect-wallet-button, .buy-tickets-button {
        padding: 6px 10px;
        font-size: 12px;
    }

    .action-buttons{
        padding: 10px 20px;
        font-size: 0.9em;
        margin-bottom: 60px;
      }

    .lottery-info-container {
        width: 95%; /* Further reduce the width for smaller mobile devices */
        padding: 10px;
        margin: 5px;
    }
    .feedback-message {
        font-size: 12px;
    }

    .info-label, .info-value {
        font-size: 12px;
    }
    .lottery-info {
        flex-basis: 48%; /* Same as above, adjust if needed */
        margin: 1%;
        max-width: 48%;
    }
    .legend {
        padding: 10px;
        margin: 10px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Even smaller for mobile phones */
    }

    .legend-item {
        padding: 6px;
        font-size: 0.6em; /* Even smaller font size for smaller devices */
    }

    
}