/* General styling and responsive container setup */
.token-owners-container {
    margin: 20px auto;
    background: #1e1e2f;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.5);
    color: #fff;
    overflow: scroll;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
}

.token-owners-table {
    width: 100%;
    border-collapse: collapse;
}

.token-owners-table th, .token-owners-table td {
    text-align: left;
    padding: 20px;
    border-bottom: 1px solid #343450;
    transition: background-color 0.3s ease;
    font-size: 15px;
}
.token-owners-table th {
    background-color: #007BFF;
    color: #ffffff;
}

.token-owners-table tr:nth-child(even) {
    background-color: #24243e;
}

.token-owners-table tr:hover {
    background-color: #2a2a40;
}


.percentage-bar {
    height: 30px;
    width: 100%;
    background-color: #343450;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
}

.percentage-fill {
    height: 30px;
    background: linear-gradient(90deg, #0f62fe, #6f86d6);
    border-radius: 7px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
}

/* Add these styles to your TokenOwners.css */

.is-contract-true {
    color: #ff6347; /* Example color for contract addresses */
  }
  
  .is-contract-false {
    color: #9acd32; /* Example color for non-contract addresses */
  }
  
  @media screen and (max-width: 768px) {
    /* Add media query adjustments if needed */
    .is-contract-true::after, .is-contract-false::after {
      content: attr(data-is-contract) " address";
      display: block;
      font-size: 0.8rem;
      color: #ccc;
      padding-top: 0.25rem;
    }
  }

  
  .token-owners-container h2{
    font-size: 30px;
    text-align: center;
    font-weight: bold;
  }

  /* Search Container */
.search-container1 {
    margin: 0 auto 20px auto; /* Centers the search bar and adds space below it */
    max-width: 900px; /* Matches the token owners container width */
    padding: 0 15px; /* Padding on the sides */
}

/* Search Input Field */
.search-container1 input {
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside the input for better readability */
    margin-bottom: 10px; /* Space below the input field */
    border: 2px solid #343450; /* Subtle border color */
    border-radius: 5px; /* Rounded corners */
    background-color: #1e1e2f; /* Matching the container background */
    color: #fff; /* White text color */
    font-size: 16px; /* Readable font size */
    transition: border-color 0.3s ease-in-out; /* Smooth transition for the border color */
}

/* Hover and Focus State for Search Input */
.search-container1 input:hover,
.search-container1 input:focus {
    border-color: #007BFF; /* Highlight border color on hover/focus */
    outline: none; /* Removes the default focus outline */
}

/* Placeholder text color */
.search-container1 input::placeholder {
    color: #bbb; /* Lighter text color for the placeholder */
    opacity: 1; /* Ensures full visibility of placeholder text */
}
