.transaction-status-image {
    display: block;
    margin: 20px auto;
    max-width: 100px; /* Adjust as needed */
    border-radius: 50%; /* Optional, for rounded images */
}

.modal-content-lotteria-status {
    background-color: #000000bd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    text-align: center;
}