.token_explorer2{
  background-color: #040e1a;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
max-width: 80%;
}

.token_description h1 {
  margin-left: 100px;
  font-size: 20px;
  }
  

.search-input {
  flex-grow: 1; /* Takes up the remaining space */
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 20px;
  border: none;
  outline: none;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2); /* Inner shadow effect */
  background-color: #123456; /* Replace with the exact color from the image */
  color: #ffffff; /* Text color */
  font-size: 16px; /* Adjust as per your design */
  transition: box-shadow 0.3s;
  width: 800px;
}

.trade_button {
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase; /* Optional: Makes the text all caps */
  position: relative;
  overflow: hidden;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.trade_button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.6s ease;
  transform: skewX(45deg);
}

.trade_button:hover::before {
  left: 100%;
}

.trade_button:hover {
  background: linear-gradient(45deg, #2575fc, #6a11cb);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.trade_button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transform: translateY(1px);
}

.trade_button:focus {
  outline: none;
}

@keyframes pulseAnimation {
  0% {
      box-shadow: 0 0 0 0 rgba(98, 39, 116, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(98, 39, 116, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(98, 39, 116, 0);
  }
}

.trade_button:not(:hover) {
  animation: pulseAnimation 2s infinite;
}


.swap_image{
width: 70px;
height: 70px;
}


.search-button {
  white-space: nowrap;
  padding: 10px 15px;
  margin-left: 5px;
  border-radius: 20px;
  border: none;
  background-color: #123456; /* Replace with the button color you prefer */
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
      background-color: #345678; /* Slightly darker shade for hover effect */
  }
}

.error-message {
  color: #ff0000; /* Error message color */
  margin-top: 10px;
}

/* Add styles for .token-data as needed */

.token-data-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -30px;
}

.token-data-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 100px;
}


.token-data-name img {
 width: 60px;
 margin: 20px;
 border: 2px solid #ccc; /* Example: 2px solid gray border */
border-radius: 50px;
}

.token-data-name h3{
  font-size: 40px;
  font-weight: bold;
  color: #dfec26;
}

.token-data-price h1{
  font-size: 30px;
  font-weight: bold;
}

.token-data-price p {
  color: red;
  font-size: 20px;
margin-left: 10px;
}

.content-selector {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid #ccc;
}
.content-selector button {
  display: flex;
  margin-right: 30px;
  background-color: #040e1a;
  color: white;
  margin-bottom: 10px;
  font-size: 20px;
  border: 2px solid transparent;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  align-items: center;
}

.content-selector img{
  margin-right: 5px;
}

.content-selector button:hover {
  color: white;
  border-bottom: 2px solid #f8e305; /* Keeps the border color on hover */
}

.content-selector button:focus {
  outline: none; /* Removes the default focus outline */
  box-shadow: 0 0 0 2px #1a3656; /* Custom focus indicator */
}



.selected-content{
  margin-top: 20px;
}

.selected-content h1{
  font-size: 30px;
}

.token-data-buttons {
  display: flex;
  margin-left: 100px;
  align-items: center;
}

.token-data-buttons button{
 margin: 5px;
}

.metamask_button{
  background-color: #f6851b; /* MetaMask color */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  border-radius: 60px;

}

.metamask_button img{

  height: 25px; /* Adjust based on your design needs */
  width: 25px;
}




#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}

@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

@media (max-width: 768px) {

  #dexscreener-embed iframe {
    position: absolute;
    width: 100%;
    height: 900px;
    top: 0;
    left: 0;
    border: 10;
  }

  #dexscreener-embed{
    display: flex;
  }

  .selected-content{
    margin-bottom: 20px;
  }
  
}





/*Mobile */
@media (max-width: 768px) {
 
  .search-input {
    flex-grow: 1; /* Takes up the remaining space */
    margin-right: 10px;
    padding: 10px 15px;
    border-radius: 20px;
    border: none;
    outline: none;
    box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2); /* Inner shadow effect */
    background-color: #123456; /* Replace with the exact color from the image */
    color: #ffffff; /* Text color */
    font-size: 16px; /* Adjust as per your design */
    transition: box-shadow 0.3s;
    width: 200px;
  }

  .token-data-name {
    flex-direction: column;
  text-align: center;
  margin-bottom: 0px;

  }
  
  .token-data-price {
    flex-direction: column;
  text-align: center;
  margin-left: 0px;

  }

  .token-data-name h3{
    font-size: 30px;
  }
  
  .token-data-price h1{
    font-size: 20px;
  }
  
  .token-data-price p {
    color: red;
    font-size: 20px;
  margin-left: 0px;
  }

  .content-selector button {
    margin-right: 5px;
    
  }

  .content-selector {
    overflow: scroll;
}

.token-data-buttons {
  margin-left: 0px;
  display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.token_description h1 {
  margin-left: 0px;
  text-align: justify;
}

.token_explorer2{
min-height: 1500px;
margin-bottom: 200px;
}
}


@keyframes moveRightToLeft {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-50%); } /* Only moving half the distance necessary due to duplicated content */
}

.audit-ticker {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  background: #121212; /* Darker background for better contrast */
  color: #fff;
  border-radius: 8px;
  margin: 20px 0;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.audit-ticker-content {
  display: flex;
  animation: moveRightToLeft 60s linear infinite;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  min-width: 200%; /* Ensuring we have enough content to continuously scroll */
}

.audit-data-point {
  background: linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%);
  color: #fff;
  margin: 0 15px;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-left: 5px solid;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  font-size: 15px;
  font-family: monospace;
  font-weight: bold;
  text-transform: capitalize;
}
.audit-data-point:nth-child(odd) {
  border-color: #ff5722;
  background: linear-gradient(135deg, #752828 0%, #ff2222 100%);
}.audit-data-point:nth-child(even) { border-color: #3f51b5; background: linear-gradient(135deg, #162d3f 0%, #435097 100%);; } /* Blue for even items */


.token-info {
  display: flex; /* Flexbox layout for horizontal alignment */
  align-items: center; /* Vertical alignment */
  gap: 20px; /* Space between items */
}

.token-info img {
  width: 100px; /* Fixed width for the image */
  height: 100px; /* Fixed height for the image */
  border-radius: 50%; /* Circle shape */
  object-fit: cover; /* Ensure the image covers the space without distortion */
  border: 2px solid #fff; /* White border around the image */
}

.token-info h3 {
  margin: 0; /* Remove default margin */
  color: #4CAF50; /* Stylish green color for titles */
  font-size: 24px; /* Larger font size for visibility */
}

.token-info p {
  margin: 10px 0; /* Margin for paragraph separation */
  line-height: 1.5; /* Improved line spacing for readability */
  color: #ccc; /* Lighter text color for less important text */
}

.token-info div a {
  color: #4CAF50; /* Green color to match the heading */
  text-decoration: none; /* No underline */
  margin-right: 15px; /* Space between links */
  font-weight: bold; /* Bold font for emphasis */
}

.token-info div a:hover {
  color: #66BB6A; /* Lighter green on hover for interaction feedback */
}

.social_logos{
  background-color: white;
  border-radius: 100px;
}

.social_logos button:disabled {
  display: none; 
  cursor: default;  
}
