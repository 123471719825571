.lock-tokens-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #121212, #1a1a1a);
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    color: #ffffff;
    margin: auto; /* Center the form in the middle of the page */
    margin-bottom: 30px;
    margin-top: 30px;

  }
  
  .lock-amount-input, .lock-period-selector, .lock-tokens-button {
    width: calc(100% - 30px); /* Adjust width considering padding */
    padding: 12px 15px;
    margin: 12px 0;
    border-radius: 8px;
    border: 1px solid #304FFE;
    background-color: #1e1e1e;
    color: #ffffff;
    font-size: 16px;
    outline: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for interactions */
  }
  
  .lock-amount-input:focus, .lock-period-selector:focus {
    box-shadow: 0 0 0 2px #304FFE88; /* Focus state with a soft glow */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  .lock-tokens-button {
    cursor: pointer;
    background-color: #304FFE;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-weight: bold; /* Make button text stand out */
  }
  
  .lock-tokens-button:hover, .lock-tokens-button:focus {
    background-color: #1a73e8;
    transform: translateY(-2px); /* Lift effect on hover/focus */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  }
  
  .interest-calculations p {
    margin: 8px 0;
    font-size: 14px; /* Slightly smaller font for secondary info */
    color: #bbbbbb; /* Lighter text color for less emphasis */
  }
  
  /* Ensure responsiveness */
  @media (max-width: 420px) {
    .lock-tokens-inputs {
      padding: 20px 10px; /* Adjust padding on smaller screens */
      max-width: 95%; /* Allow some margin on very small screens */
    }
    
    .lock-amount-input, .lock-period-selector, .lock-tokens-button {
      width: calc(100% - 20px); /* Adjust for smaller padding */
    }
  }
  /* Base container adjustments for flex display and background */
.locks-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Spacing between cards */
    background-color: #0d1117; /* Enhanced contrast with a darker base */
    padding: 20px;
  }
  
  /* Card styling with refined gradient and shadow for depth */
  .lock-info {
    flex: 1;
    min-width: 280px; /* Minimum width for cards */
    background: linear-gradient(145deg, #24262d, #1e2025); /* Refined gradient for depth */
    padding: 20px;
    border-radius: 12px; /* Increased radius for modern look */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Enhanced shadow for more depth */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added shadow transition */
  }

  .lock-info h3 {
    font-size: large;
  }

  .lock-info p {
    font-size: medium;
  }
  
  
  .lock-info:hover {
    transform: scale(1.05); /* Slightly enlarge cards on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6); /* Increase shadow for elevated effect */
  }
  
  /* Enhanced button styling with refined gradients and hover effects */
  .btn-claim-interest, .btn-claim-principal {
    padding: 12px 20px;
    border: none;
    border-radius: 8px; /* Rounded edges for a modern look */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    color: #ffffff;
    flex-grow: 1;
    margin-right: 10px; /* Ensures gap between buttons */
    background: linear-gradient(145deg, #00d1b2, #00b89c); /* Green gradient refined */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Deeper shadow for 3D effect */
  }
  
  .btn-claim-principal {
    background: linear-gradient(145deg, #ff3860, #e62e4d); /* Red gradient refined */
  }
  
  .btn-claim-interest:hover, .btn-claim-principal:hover {
    background-position: right center; /* Shift gradient on hover for dynamic effect */
    transform: translateY(-2px); /* Lift button effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* Increase shadow on hover for depth */
  }
  
  /* Disabled button styling with grayscale effect */
  .btn-claim-interest:disabled, .btn-claim-principal:disabled {
    background-color: #3a3a3a;
    color: #777;
    cursor: not-allowed;
    opacity: 0.7; /* Slightly less opacity for better visibility */
    box-shadow: none; /* Flatten the button to indicate inactive state */
  }
  
  /* Responsive adjustments for a fluid experience */
  @media (max-width: 768px) {
    .locks-container {
      flex-direction: column;
      gap: 15px; /* Adjust gap for tighter mobile spacing */
    }
  
    .lock-info {
      min-width: auto; /* Adjusts card width on smaller screens for full-width cards */
    }
  
    .button-group {
      flex-direction: row; /* Adjust if necessary for button layout */
    }
  
    .btn-claim-interest, .btn-claim-principal {
      width: auto; /* Adjust button width for mobile devices */
      margin: 10px 0; /* Adjust margin for vertical spacing */
    }
  
    .btn-claim-interest:last-child {
      margin-right: 0; /* Remove margin for the last button to align properly */
    }
  }
  


  action-buttons {
    background: #2a2f45; /* Dark background similar to the image */
    border-radius: 10px;
    padding: 20px;
    margin: 20px ; /* Centers the action-buttons div */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Spacing between buttons */
    width: fit-content;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr; /* Three columns: left, middle, right */
    gap: 20px; /* Adjust the gap between columns */
    align-items: center; /* Vertically center content */
    margin-top: 30px;
  }
  
  /* Style for the left side (image) */
  .left-side {
    text-align: center;
  }
  
  /* Style for the app logo */
  .app-logo {
    max-width: 100px; /* Adjust the maximum width of the logo */
    height: auto; /* Maintain the aspect ratio of the logo */
    animation: rotate 20s linear infinite;

  }
  
  /* Style for the middle (app name) */
  .middle {
    text-align: center;
  }
 
  
  /* Style for the right side (action buttons and wallet address) */
  .right-side {
    text-align: center;
  }
  
  .wallet-address-display, .wallet-balance-display {
    background-color:#1e1e1e;  /* Matching background color */
    color: #ffffff;
    padding: 12px 20px;
    border-radius: 20px;
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-left: 10px;
    border: 1px solid #304FFE;

  }
  
  .wallet-address-display:hover, .wallet-balance-display:hover, .custom-token-balance-display:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  .custom-token-balance-display {
    background-color: #1e1e1e; /* Matching background color */
    color: #ffffff; /* White text color */
    padding: 12px 20px;
    border-radius: 20px;
    margin: 10px 0;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: grid;
    gap: 8px; /* Adds space between items in the grid */
    grid-template-columns: 1fr; /* Adjusts columns in the grid */
    align-items: center;
    justify-items: center; /* Ensures content is centered */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    border: 1px solid #304FFE;
    margin-right: 10px;
}

.custom-token-balance-display:hover {
    transform: translateY(-5px); /* Subtle hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.custom-token-balance-display span {
    font-size: 16px; /* Adjusted font size */
    font-weight: normal; /* Less emphasis on the value's weight */
    color: #e0e0e0; /* Lighter shade for better contrast */
    text-shadow: none; /* Clearer text */
}


  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .custom-token-balance-display {
      padding: 10px 15px; /* Smaller padding on smaller screens */
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  
    .custom-token-balance-display span {
      font-size: 16px; /* Adjust value font size accordingly */
    }
  }
  

  .connect-wallet-button,
  .buy-tickets-button {
    background: #4c8bf5; /* Blue background for the buttons */
    border: none;
    border-radius: 20px; /* Rounded borders for the buttons */
    color: white;
    font-size: 1em;
    padding: 15px 30px; /* Padding inside the buttons */
    text-transform: uppercase;
    letter-spacing: 1px; /* Spacing between letters for a more defined look */
    width: 100%; /* Buttons take full width of the .action-buttons div */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button background color */
    margin-left: 10px;
    
  }
  
  .connect-wallet-button:disabled,
.buy-tickets-button:disabled {
  background: #3a3f55; /* Darker background for disabled state */
  cursor: not-allowed;
}

.connect-wallet-button:not(:disabled):hover,
.buy-tickets-button:not(:disabled):hover {
  background: #6699ff; /* Lighter blue for hover state */
}


  .connect-wallet-button {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite, pulse 2s infinite;  
}
  
:root {
    background: black; /* Dark background similar to the image */
    color: white; 
}

.info-value {
    font-size: 24px;
    font-weight: bold;
    animation: pulse 2s infinite;

  }
  .percentage-buttons-container {
    display: flex;
    margin-top: 10px; /* Adjust spacing as needed */
}

.percentage-button {
    background-color: #4c8bf5; /* Blue background */
    border: none;
    color: white;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease; /* Smooth transitions */
}

.percentage-button:hover {
    background-color: #6699ff; /* Lighter blue on hover */
    transform: translateY(-2px); /* Lift effect on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow on hover */
}

.percentage-button:focus {
    outline: none; /* Remove default focus outline */
}

.percentage-button:active {
    transform: translateY(1px); /* Press effect */
}


  

  @media (max-width: 768px) {
    .lottery-container {
        padding: 10px;
    }

    .grid-container {
        display: list-item;
        grid-template-columns: 1fr 1fr; /* Two equal columns */
        gap: 20px; /* Adjust as needed */
      }
    }
    @keyframes scrollLeft {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-50%);
        }
    }
    
    .scrolling-banner {
        overflow: hidden;
        background-color: #4c8bf5; /* Adjust the background color as needed */
        color: #fff; /* Text color */
    }
    
    .scrolling-banner .scroll-content {
        display: flex; /* Aligns items in a row */
        animation: scrollLeft 30s linear infinite;
    }
    
    .scrolling-banner .scroll-content p {
        margin-right: 50px; /* Adds space between paragraphs */
        white-space: nowrap; /* Prevents text wrapping */
    }
    
    .scrolling-content.paused {
        animation-play-state: paused;
      }
      


/* Modal.css - Assuming this file exists and is linked to your Modal component */

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly darker backdrop for focus on modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Ensure modal is above most content */
  }
  
  .modal-content {
    background: linear-gradient(145deg, #121212, #1a1a1a); /* Similar to .lock-tokens-inputs */
    padding: 25px;
    border-radius: 12px; /* Match your input/button styles */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Consistent shadow */
    width: 90%; /* Responsiveness */
    max-width: 600px; /* Adjust based on your content */
    color: #ffffff; /* Text color consistent with your theme */
    outline: none; /* Remove default focus outline */
  }

  .modal-content p {
  font-size: medium;
  }

  .modal-content h3 {
    font-size: large;
    }
    
  
  .modal-close-button {
    cursor: pointer;
    background-color: #304FFE; /* Button color similar to your other buttons */
    color: #ffffff;
    border: none;
    border-radius: 8px; /* Consistency with other buttons */
    padding: 8px 16px; /* Adequate padding */
    font-size: 16px; /* Matching your font sizing */
    float: right; /* Positioning */
    margin: -20px -20px 20px 20px; /* Adjusted margins */
  }
  
  .modal-close-button:hover {
    background-color: #1a73e8; /* Hover effect similar to your button styles */
  }
  
  /* Since your design seems responsive, consider adding media queries for smaller screens to ensure the modal looks good on all devices */
  @media (max-width: 768px) {
    .modal-content {
      padding: 15px; /* Smaller padding on smaller screens */
      max-width: 95%; /* Full width with a bit of margin */
    }
  }
  