/* Notification.css */
.notification {
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .notification.success {
    background-color: #4CAF50; /* Green */
  }
  
  .notification.error {
    background-color: #F44336; /* Red */
  }
  
  .close-notification {
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
  }
  