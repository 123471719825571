/* src/components/Sidebar.css */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    background: #020202;
    padding: 20px;
    transition: width 0.3s ease, padding 0.3s ease;
    flex: 0 0 200px;
    height: 100vh;
    z-index: 1000; /* Ensure sidebar is above other content */
    border-right: 2px solid #ffffff; /* Example: 2px solid gray border */

}

.sidebar a {
    color: white;
    display: block;
    text-decoration: none;
    padding: 10px;
    margin: 5px 0;
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.sidebar a:hover {
    background-color: #416894;
}

.sidebar-logo {
    text-align: center;
    width: 100px; /* Original size */
    transition: width 0.3s ease; /* Smooth transition for width */
}

/* Improved mobile styling */
@media (max-width: 768px) {
    .sidebar {
        width: 100%; /* Adjusted width for better space management */
        padding: 10px; /* Reduced padding */
        overflow-x: hidden; /* Prevent horizontal scrolling */
        display: none;
    }

    .sidebar a {
        font-size: 14px; /* Adjust font size for better readability */
        padding: 8px 6px; /* Adjust padding for touch targets */
    }

    .sidebar-logo {
        width: 50px; /* Adjust logo size */
    }

    /* Optional: Add a class for toggling the sidebar visibility */
    .sidebar-hidden {
        left: -100px; /* Move sidebar out of view */
        transition: left 0.3s ease; /* Smooth transition for hiding/showing */
    }
}

/* Hidden state for the sidebar */
.sidebar.hidden {
    display: none;
  }
  
  /* Style for the toggle button */
  .sidebar-toggle {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
  }
  
  /* Media query for devices with a max width of 768px */
  @media (max-width: 768px) {
    .sidebar.visible {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: stretch;
        align-items: baseline;
        justify-content: center;

    }

    .sidebar-toggle {
       display:  none;
      }

    .bottom-nav {
        display: flex; /* Show bottom navigation on small screens */
      }

      .sidebar {
        display: none; /* Hide sidebar on small screens */
      }
  }

  

  /* BottomNav.css */
.bottom-nav {
    display: none; /* Hidden by default */
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
  }

  .nav-item {
    display: inline-block;
    padding: 10px;
    width: 20%;
    text-align: center;
  }

  .bottom-menu-bar {
    z-index: 10;  /* Lower than the modal's z-index */
}

.wui-flex {
  align-items: center;
  z-index: 1000;
}

