.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .modal-content-transaction {
    background-color: #000000bd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    text-align: center;
    
  }
  
  .loading-gift img {
    margin: 20px auto;
    display: block;
    max-width: 100px; /* Adjust size as needed */
  }
  
  .close-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: auto;
    display: inline-block;
  }
  
  /* Add responsiveness */
  @media (max-width: 600px) {
    .modal-content {
      width: 80%;
      padding: 10px;
    }
  
    .loading-gift img {
      max-width: 80px; /* Smaller on mobile */
    }
  
    .close-button {
      padding: 8px 16px; /* Smaller button on mobile */
    }
  }
  