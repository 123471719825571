/* General container styling */
.honeypot-container {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

/* Styling for the honeypot alert */
.honeypot-alert {
    color: #d9534f; /* Red color for alert messages */
    border-left: 5px solid #d9534f; /* Red border on the left for emphasis */
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff5f5; /* Light red background */
}

/* Styling for the sell tax section */
.sell-tax {
    color: #5bc0de; /* Blue color for informational messages */
    border-left: 5px solid #5bc0de; /* Blue border on the left for emphasis */
    padding: 10px;
    background-color: #f5f8fa; /* Light blue background */
}

/* Header styles */
.honeypot-header, .sell-tax-header {
    margin-top: 0;
    color: #333;
}

/* Paragraph styles */
.honeypot-text, .sell-tax-text {
    font-size: 16px;
}
